export default {
  lang: "zh",
  login: {
    login: "登录",
    logout: "注销",
    welcome: "欢迎",
    account: "账号",
    password: "密码",
    forgotPassword: "找回密码",
    app: "下载APP",
    qrCodeApp: "扫一扫下载电梯云APP",
    chromeDown: "立即下载",
    chromeLogin: "建议使用chrome浏览器登录",
    phone: "手机号",
    verify: "验证码",
    newPassword: "新密码",
    submit: "提交",
    sendMSG: "发送短信",
    resend: "重新发送",
    getVer:"获取验证码",
    emptyVer:"验证码不能为空",
    verSuccess:"短信发送成功,10分钟内有效",
    verFail:"短信发送失败",
    verNotRight:"验证码不正确或者已过期",
    tips: {
      account: "请输入账号",
      password: "请输入密码",
      notNull: "不能为空",
      logout: "确定退出登录吗？",
    },
  },

  headerBar: {
    app: "下载APP",
    unavailable: "视频监控未开通",
    serial: "串口授权",
    connect: "正在连接...",
    nonsupport: "当前环境不支持",
    connectionFailed: "视频监控连接失败",
    available: "视频监控正常",
    newConnection: "视频监控有新呼叫",
    landed: "其他地方登录",
    setting: "账户设置",
    logout: "注销登录",
    uiSetting: "界面设置",
    globalMute: "全局静音",
    cancelMute: "取消静音",
  },
  // 首页
  home: {
    lifts: "入网总数",
    onlineLifts: "在线总数",
    repairLifts: "当前急修",
    parts: "开通日周月报告总数",
    units: "台",
    elevatorStatus: "电梯状态",
    faultType: "故障类型",
    documents: "对接文档",
    events: "实时事件",
    faults: "实时故障",
    alarms: "警铃报警",
    maintenance: "检修",
    mainPowerLoss: "主电源掉电",
    enterStopMode: "停止服务",
    changePassword: "修改密码",
    ignore: "忽略",
    tip: {
      passwordSimple: "密码过于简单，建议您修改密码",
    },
    users: "用户数量",
    liftsLocation: "电梯分布",
  },
  route: {
    maintPlan:"维保计划",
    bluetoothRecords:"蓝牙连接记录",
    bigDataManager: "数据统计",
    aiManger: "AI监管",
    createCompany: "制造单位",
    maintCompany: "维保单位",
    propertyCompany: "物业单位",
    installCompany: "安装单位",
    changeCompany: "改造单位",
    useUnitCom: "使用单位",
    agent: "代理商",
    camera: "智联摄像",
    monitorDevice: "摄像头管理",
    home: "首页",
    account: "账号管理",
    notice: "公告信息",
    uiSetting: "界面设置",
    noticeDetail: "公告详情",
    elevator: "电梯档案",
    peopleCounting: "人数统计",
    elevatorInfo: "电梯信息",
    device: "设备管理",
    danger: "健康指数",
    elevatorUser: "电梯用户",
    monitor: "电梯监控",
    useUnitMonitor: "小区监控",
    map: "GIS地图",
    singleMonitor: "单梯监控",
    videoMonitor: "视频监控",
    videoCall: "视频通话",
    monitorCenter: "监控中心",
    emergencyRepair: "电梯急修",
    repairJob: "急修工单",
    annualInspection: "电梯年检",
    annualInspectionRecord: "年检记录",
    userRole: "用户角色",
    user: "用户管理",
    role: "角色管理",
    baseInfo: "基础信息",
    useUnit: "使用单位",
    gatewayVersion: "网关程序版本",
    thirdPartyPlatform: "第三方平台",
    messagePush: "消息推送",
    parts: "配件管理",
    partsType: "配件类型",
    company: "公司管理",
    qualitySupervision: "质检机构",
    videoTerminal: "智能屏幕",
    videoUser: "视频用户",
    dtuTest: "网关测试",
    floorDisplay: "楼层对照",
    faultTemplate: "故障模板",
    developerPermission: "开发者",
    userDebugInfo: "调试人员",
    potentialFaultsetting: "安全隐患设置",
    deviceChangeRecord: "事件记录(废弃)",
    mb: "事件记录",
    mbPowerLoss: "电源系统异常（停电）",
    faultRecords: "故障记录",
    integration: "对接设置",
    alarmRecords: "警铃报警",
    elevatorMaintenance: "电梯维保",
    maintenanceWorkOrder: "保养工单",
    safeCheckOrder: "抽查工单",
    maintenanceSetting: "维保设置",
    modSalesOrder: "改造订单",
    upkeep: "维保管理",
    accessPlatform: "接入平台",
    oldMaintenanceWorkOrder: "原保养工单",
    msgSend: "短信发送记录",
    userTakeRecord: "呼梯记录",
    msgTemplate: "短信配置",
    sysItem: "系统参数",
    system: "系统管理",
    event: "事件管理",
    document: "小程序",
    video: "视频资料",
    banner: "轮播资料",
    product: "产品资料",
    fileManage: "扫码文件管理",
    ztVersion: "私有平台",
    equip: "网关设备",
    shanghai: "上海对接",
    wuxi: "无锡对接",
    chongqing: "重庆对接",
    volks: "沃克斯对接",
    shenyang: "沈阳对接",
    jiaxing: "嘉兴对接",
    guangzhou: "广州对接",
    quzhou: "衢州对接",
    guangxi: "广西对接",
    zhejiang: "浙江对接",
    fujian: "福建对接",
    nanchang: "南昌对接",
    wuhan: "武汉对接",
    shaoxing: "绍兴对接",
    hangzhou: "杭州对接",
    ningbo: "宁波对接",
    liaoning: "辽宁对接",
    huzhou: "湖州对接",
    elevatorNetApply: "入网申请",
    homeUser: "家用梯用户",
    newMsgSend: "消息记录",
    deviceGateway: "网关设备",
    tenant: "租户管理（勿用）",
    Operations: "运营管理",
    kanban: "看板展示",
    userLoginLog: "登录日志",
    userOperateLog: "操作日志",
    elevatorSetting: "档案设置",
    potentialFaultSetting: "安全隐患设置",
    systemSetting: "系统设置",
    contract: "合同管理",
    programCard: "节目单",
    smartScreen: "智能屏幕",
    tpLinkVideo: "TP摄像头",
    vmVideo: "智能摄像",
    eocdPotential: "安全隐患",
    dayWeekMonthManger: "日周月监管",
    dayControl: "日管控",
    weekCheck: "周排查",
    vxeTableTest: "VxeTable测试",
    monthDispatch: "月调度",
    checkTemplates: "排查模板",
    brand: "电梯品牌",
    feedBack: "意见反馈",
    paramsManage: "参数管理",
    paramsModule: "参数模块管理",
  },
  tenant: {
    dataBelong: "数据所属",
    tenantManage: "租户管理",
    unitInformation: "单位信息",
    user: "用户",
    tplinkCamera: "tplink摄像头",
    smartScreen: "智能屏幕",
    belongUnit: "所属单位",
    belongTenant: "所属租户",
    elevatorId: "电梯ID",
    tenant: "租户",
    loginFailed: "登陆失败",
    settingsSelected: "未选中任何设置项",
    tenantNotNull: "租户不能为空",
    tenantStatus:"租户状态",
    tenantName:"租户名称",
    company:"初始公司",
    freeze:"冻结",
    createTime:"创建时间",
    updateTime:"更新时间",

  },
  // 入网申请
  elevatorNetApply: {
    eleNotEmpyty:"已入网电梯入网日期不能为空",
    dtuNotEmpyty:"已入网电梯DTU编号不能为空",
    add: "新增",
    save: "保存",
    edit: "编辑",
    cancel: "取消",
    delete: "删除",
    generateSuccess: "生成成功",
    downloadTemplate: "下载导入模板",
    importElevator: "导入电梯",
    createSource: "创建来源",
    dtuCode: "DTU编号",
    factoryNumber: "出厂编号",
    elevatorName: "电梯名称",
    useUnit: "使用单位",
    manufacturerCompany: "制造单位",
    maintenanceCompany: "维保单位",
    webAdd: "网页新增",
    webImport: "网页导入",
    app: "App",
    apiImport: "Api导入",
    underReview: "审核中",
    reviewed: "已审核",
    uploadType: "请上传.xlsx文件类型",
    importSuccess: "导入成功",
    importFailure: "导入失败",
    importOnly: "一次只能导入1个文件",
    operate: "操作",
    useUnitDistrict: "使用单位地区",
    companyInfo: "企业信息",
    useUnitAddress: "使用单位详细地址",
    manufactured: "制造企业",
    creditCode: "社会信用代码",
    maintComName: "维保企业",
    maintEmpName: "维保人员",
    maintEmpPhone: "维保人员手机",
    propertyCom: "物业公司",
    elevatorInfo: "电梯信息",
    sim: "SIM卡号",
    nodeCode: "节点",
    buildingNo: "楼号",
    ladderNo: "梯号",
    elevatorType: "梯种",
    brand: "品牌",
    elevatorModel: "规格型号",
    load: "载重",
    speed: "额度速度(m/s)",
    dtuModel: "DTU型号",
    controlModel: "控制方式",
    regCode: "设备代码",
    exFactoryDate: "出厂日期",
    installationDate: "安装日期",
    videoChannelId: "轿厢摄像头ID",
    addFloorMappings: "请添加楼层信息",
    displayCode: "显示代码",
    floorLocation: "楼层位置",
    floorMappings: "楼层对照",
    proName: "项目名称",
    maintCreditCode: "维保企业信用代码",
    createCreditCode: "制造企业信用代码",
    installAddress: "安装地址",
  },
  monitorDevice: {
    id: "设备id",
    name: "设备名称",
    url: "视频地址",
    location: "视频位置",
    useUnit: "使用单位",
  },
  user: {
    labelWidth: "150px",
    roleIds: "用户权限",
    station: "分公司下所属站点",
    serviceStation: "服务站",
    childCom: "区域下所属公司",
    branch: "分公司",
    area: "地域划分",
    unlockSuccess: "解锁成功",
    unlock: "解锁",
    lockedAccount: "被锁定的账号",
    accountUnlock: "锁定账号解锁",
    tenant: "租户",
    correspondRole: "对应角色",
    assignAuthority: "分配权限",
    isMaintWorker: "是否维保工",
    userNameRule: "账号为4~25位字符，只能包含英文字母、数字、@、点、下划线",
    passwordRule: "密码为8~30位字符，必须包含大写字母、小写字母、数字",
    nameRule: "姓名不能为空",
    phoneRule: "手机号不能为空",
    validNumberNumber: "请输入正确的手机号",
    userTypeRule: "用户类型不能为空",
    maintWorkerRule: "是否维保工不能为空",
    correspondRoleRule: "对应角色不能为空",
    userInfo: "用户信息",
    user: "用户",
    username: "账号",
    password: "密码",
    type: "用户类型",
    messageSendType: "通知（验证码）发送方式",
    clientUser: "C端用户",
    cegion: "区域",
    afterSale: "售后",
    name: "姓名",
    phone: "手机号",
    repair: "急修短信",
    alarm: "警铃短信",
    maintenance: "维保短信",
    company: "企业名称",
    lastLoginTime: "最后登陆时间",
    quality: "质监局",
    mail: "邮箱",
    maintainer: "维保工",
    messageControl: "短信控制",
    changePwdState: "可改密码",
    resetPassword: "重置密码",
    unselectedRole: "未选角色",
    selectedRole: "已选角色",
    reset: "重置",
    is: "的",
    types: {
      0: "星辰用户",
      1: "企业管理员",
      2: "企业普通用户",
      3: "质监局管理员",
      4: "质监局普通用户",
      9: "普通用户",
    },
    tip: {
      mail: "请输入正确的邮箱",
      phone: "请输入正确的手机号",
      password: "必须同时包含字母和数字，长度在8-30之间",
      tips: "密码为A123456b",
      successful: "重置密码成功",
      failed: "重置密码失败",
    },
    account: {
      baseInfo: "基本信息",
      oldPassword: "旧密码",
      newPassword: "新密码",
      confirm: "确认新密码",
      tip: {
        confirm: "两次输入密码不一致",
      },
    },
  },
  // 电梯档案
  elevator: {
    eocdException: "EOCD异常",
    code: "编码",
    agent: "代理商",
    simExpireDate: "SIM卡到期时间",
    dockingPlatform: "对接平台",
    maintenanceMan: "维保工",
    report: "统计",
    batchCopy: "批量复制当前电梯",
    sameMaintenanceWorker: "不能选择同一个维保工!",
    maintenanceWorker: "请至少选择一个维保工",
    selectMaintenance: "请选择维保企业",
    parameterEmpty: "参数不能为空",
    selectFloorDisplay: "请先选择楼层对照",
    esdt: "ESDT网关",
    esdtFault: "ESDT故障记录",
    emt: "EMT网关",
    emtFault:"EMT故障记录",
    nvrFault: "NVR故障记录",
    otherRecord: "其他记录",
    selfProtectionRecord: "自保记录",
    elevatorDetails: "电梯详情",
    baseInfo: "基本信息",
    iot: "物联网",
    elevatorParts: {
      elevatorParts: "电梯部件",
      parts: "配件",
      add: "添加",
      category: "类别",
      number: "编号",
      name: "名称",
      serialNumber: "序列号",
      floor: "楼层",
      status: "状态",
      activated: "已认证",
      notActivated: "未认证",
      remove: "移除/未解绑",
      activateTime: "激活时间",
      activator: "操作者",
      createTime: "创建时间",
      replace: "更换",
      replacementRecord: "更换记录",
      oldParts: "旧配件",
      newParts: "新配件",
      replacementTime: "更换时间",
    },
    longAntLat: "设备经纬度",
    unknown: "未知",
    record: "电梯记录",
    locationInfo: "位置信息",
    selectElevator: "请先选择电梯",
    selectReporting: "请选择报告统计周期",
    selectOne: "请至少选择一个",
    unselectedElevator: "未选电梯",
    selectedElevator: "已选电梯",
    checkMultiReport: "查看多梯报告",
    saveReport: "是否保存为多梯报告，以便快速查看？",
    reportName: "模板名称",
    elevatorCount: "电梯数量",
    templateNameNotNull: "模板名称不能为空",
    createReport: "生成报告",
    reportStateCycle: "报告统计周期",
    singleElevator: "单梯报告",
    multiElevator: "多梯报告",
    floorQr: "楼层二维码打包下载",
    ownerPhoneNo: "业主手机号",
    isHomeUse: "是否家用",
    monitorTime: "监控连续时间/分钟",
    autoGenerate: "自动生成日周月报告",
    registerCode1: "注册代码",
    follow: "关注",
    batchModification: "批量修改",
    faultTemplate: "故障模板",
    salesman: "业务员",
    salesmanTel: "业务员手机",
    alarmDelayTime: "报警延迟时间",
    floorComparisonTable: "楼层对照",
    callElevator: "呼梯设置",
    ota: "OTA",
    confirmChoice: "确定选择",
    projectName: "项目名称",
    faultCode: "故障代码",
    belongCompany: "所属公司",
    elevator: "电梯",
    addElevator: "添加电梯",
    no: "电梯编号",
    address: "详细地址",
    person: "联系人",
    elevatorType: "梯种",
    registerCode: "设备代码",
    name: "电梯名称",
    brand: "品牌",
    model: "规格型号",
    maintWorker: "维保工",
    maintType: "维保类型",
    safetyOfficer: "安全员",
    safetyDirector: "安全总监",
    safetyMainer: "主要负责人",
    buildingNo: "楼号",
    elevatorNo: "梯号",
    dtuCode: "DTU编号",
    nodeCode: "节点",
    monitorType: "接入方式",
    monitorType1: "协议",
    monitorType2: "外围传感器",
    gateWayType: "网关",
    gateWayType1: "ESDT",
    gateWayType2: "TPL-NVR",
    gateWayType3: "EMT",
    dtuModel: "DTU型号",
    simCardNo: "SIM卡号",
    iccid: "ICCID",
    exFactoryNo: "出厂编号",
    controlCabinetLeaveNo: "控制柜出厂编号",
    exFactoryNoWx: "无锡出厂编号",
    outerDeviceWx: "无锡外设编号",
    statusWx: "是否接入",
    maintPeriod: "维保周期(天)",
    whereUsed: "使用场所",
    videoEquip: "视频设备",
    deviceGateway: "网关设备",
    notInstalled: "未安装",
    installed: "已安装",
    installed_plug: "已安装(插件形式)",
    installed_link: "已安装(链接形式)",
    videoChannelId: "轿厢摄像头ID",
    videoUrl: "轿厢视频链接",
    motorRoomVideoUrl: "轿厢视频FLV",
    video: "视频",
    joinStatus: "入网状态",
    isParallel: "并联/群控",
    openStatus: "是否公开",
    open: "公开",
    notOpen: "不公开",
    halfOpen: "半公开",
    onlineStatus: "在线状态",
    activatedPartsStatus: "认证状态",
    accessStatus: "接入状态",
    accessPlatform: "对接平台",
    activated: "已认证",
    notActivated: "未认证",
    all: "全部",
    accessed: "已接入",
    notAccessed: "未接入",
    useStatus: "使用状态",
    useUnit: "使用单位",
    property: "所属物业",
    maintenanced: "维保企业",
    propertyCom: "物业公司",
    qualityOrgan: "质检机构",
    manufactured: "制造企业",
    installationEnterprise: "安装企业",
    reconstructed: "改造企业",
    locCode: "位置码",
    exFactoryDate: "出厂日期",
    installationDate: "安装日期",
    joinDate: "入网日期",
    serviceDate: "使用日期",
    test: "调试",
    check: "检测",
    yes: "是",
    not: "否",
    floors: "层数",

    ratedSpeedOfEscalator: "额定速度(m/s)",
    angleOfEscalator: "倾斜角度(度)",
    riseOfEscalator: "提升高度(m)",

    widthOfEscalator: "宽度(m)",
    lengthOfEscalator: "长度(m)",

    ratedSpeedOfMovingWalk: "自动人行道额定速度(单位：m/s)",
    lengthOfMovingWalk: "自动人行道使用区段长度(单位：m)",
    angleOfMovingWalk: "自动人行道倾斜角度(单位：度)",
    widthOfMovingWalk: "自动人行道名义宽度(单位：m)",
    highestFloorOfElevator: "电梯物层楼数(单位：层)",
    ratedSpeedOfElevator: "额定速度(m/s)",
    ratedSpeedOfElevatorRule: "额定速度",
    ratedLoadOfElevator: "额定载重量(kg)",


    floorDisplayTemplate: "楼层对照",
    actualFloor: "实际楼层",
    displayedFloor: "显示楼层",
    stopStatus: "当前楼层是否停靠",
    currentFloorCode: "状态楼层代码（A0）",
    faultFloorCode: "故障楼层代码（MC）",
    faultFloor: "故障楼层",
    addFloorDisPlay: "添加楼层对照",
    peopleStuckAlarmDelayTime: "困人报警延迟时间(单位:秒)",
    notAvailableAlarmDelayTime: "故障停梯故障报警延迟时间(单位:秒)",
    finishDelayTime: "完工延迟时间(单位:秒)",
    longitude: "经度",
    latitude: "纬度",
    joined: "已入网",
    notJoined: "未入网",
    useStatus_1: "在用",
    useStatus_2: "报废",
    useStatus_3: "停用",
    useStatus_4: "注销",
    maintenanceBySelf: "自保",
    maintenanceByOthers: "他保",

    production: "生产",
    using: "使用",
    maintenance: "维保",
    system: "系统",
    fault: "故障",
    enterprise: "企业信息",
    parameters: "电梯参数",
    extensionParameters: "扩展参数",

    modifyLocation: "修改位置",

    generalParameters: "通用参数",
    floorDisplay: "楼层对照",
    alarmParameters: "报警参数",
    location: "电梯位置",

    emergencyRecord: "急修记录",
    maintenanceRecord: "维保记录",
    yearCheckRecord: "年检记录",
    faultRecord: "故障记录",
    deviceEvent: "事件记录",
    potentialFault: "安全隐患",
    device: "组网设备",
    runTimes: "运行次数",
    gradeAlarming: "分级报警",
    maintComSelectTip: "请先选择维保企业",
    propertyComSelectTip: "请先选择物业企业",
    elevatorDebuggingPlatform: "电梯调试平台",
    elevatorCheck: "电梯检测",
    lock: "锁梯",
    unlock: "取消锁梯",
    Lock: "锁定",
    Unlock: "解锁",
    closeAuth: "关闭权限",
    openAuth: "开放权限",
    activationBinding: "激活绑定",
    AllBoards: "系统内所有板子",
    elseBoards: "其他（扩展板等）",
    simCardBinding: "Sim卡绑定",
    groupControlBoard: "群控板（并联）",
    frequencyConverter: "变频器",
    carBoard: "轿厢板",
    sedanRoof: "轿顶板",
    callBoard: "外呼板",
    changeProbationPeriod: "更改试用期",
    escalatorLock: "扶梯锁梯",
    escalatorUp: "扶梯上行",
    escalatorDown: "扶梯下行",
    escalatorRun:"扶梯运行",
    escalatorStop:"扶梯停止",
    energryRun:"节能运行",
    fire:"消防",
    breakSystem:"附加制动器",
    UnintendedReversal:"非操纵逆转",
    missingStep:"梯级或踏板的缺失",
    handSpeed:"扶手带速度偏离",
    breakFail:"工作制动器故障",
    otherFault:"其他阻止自动扶梯和自动人行道再启动的故障",
    datSta:"统计数据",
    slow:"慢",
    fast:"快",
    stop:"停",
    day: "天",
    hour: "小时",
    openDebugAuth: "远程开放调试权限",
    grade: "等级",
    time: "时间",
    stopOrStart: "停复梯",
    lockOrUnlock: "远程解锁合同号",
    contractNo: "合同号",
    refresh: "刷新",
    operationRecord: "操作记录",
    type: "类型",
    order: "命令",
    status: "状态",
    operator: "操作人",
    executing: "进行中",
    success: "成功",
    error: "失败",
    overTime: "超时",
    copy: "复制",
    batchAdding: "批量新增电梯",
    addNumber: "添加数量",

    exportExcel: "导出",
    importSimInfo: "导入Sim卡信息",
    tip: {
      maintPeriod: "维保周期必须大于0",
      operateSuccess: "执行成功",
      operateError: "执行失败",
      atLeastOne: "请至少选择一项",
      daysAndHours: "请正确填写天数和小时",
      gradeAndTime: "请正确填写等级和时间",
      chooseFirst: "请先选择",
      want: "确定执行",
      contractNo: "合同号不能为空",
      batchAddition: "批量添加不需填写设备代码",
      needRegNO: "不能为空，没有请填出厂编号",
    },
  },
  deviceGateway: {
    turnOn: "开启",
    closed: "关闭",
    edit:"编辑",
    isSendAlarmSms: "警铃短信",
    isInsertJxOrder: "创建工单",
    MakeSureProtocols: "确定设置支持协议？",
    dtuNotNull: "DTU不能为空",
    lastTime1: "最后时间",
    pushCount: "推送数目",
    dtuNode: "DTU编号:节点",
    processing: "推送数目过多,网关程序可能存在异常,请联系相关人员做相对处理",
    GatewayPushStatistics: "网关推送统计",
    labelWidth1: "80px",
    refresh: "刷新",
    sendA0: "发送A0",
    type: "类型",
    packet: "数据包",
    receivingTime: "接收时间",
    repair: "沈阳-A0状态数据修复",
    runNumber: "运行次数",
    runTime: "运行时间",
    setting: "设置",
    read: "读取",
    clear: "清空",
    lastTime: "A0当前运行次数与上一次对比大小",
    dockingOffline: "沈阳对接离线60秒断电",
    OfflinePower: "离线->断电",
    A0Running: "A0运行启停时间",
    initialRunNumber: "初始运行次数",
    initialRunTime: "初始运行时间",
    currentRunNumber: "当前运行次数",
    currentRunningTime: "当前运行时间",
    polarP: "极对数p",
    motorType: "电机类型",
    synchronous: "同步",
    asynchronous: "异步",
    MkEvent: "模拟电梯事件（MK新事件）",
    automatic: "自动",
    overhaul: "检修",
    normal: "正常",
    AlarmBell: "警铃报警",
    PowerGateway: "网关上电",
    GatewayPowered: "网关掉电",
    orderMkEvent: "模拟急修工单（MK新事件）",
    NotTrappingPeople: "不困人",
    TrappingPeople: "困人",
    faultCode: "故障码",
    sendOrder: "发送工单",
    confirmSettings: "确定设置?",
    clearSuccess: "清空成功！",
    eventSendSuccess: "事件发送成功！",
    orderSendSuccess: "工单发送成功！",
    confirmClear: "确定清空?",
    elevatorBind: "电梯绑定",
    groupControl: "是否并联群控",
    normallyOpen: "常开",
    normallyClose: "常闭",
    updateOnlineStatus: "更新在线状态",
    updateBindingStatus: "更新绑定状态",
    gatewayRouteSet: "网关路由设置",
    pushStatistics: "推送统计",
    monitor: "监控",
    video: "视频",
    selfStudy: "自学习",
    close: "normal close",
    open: "normal open",
    singleDoor: "单门",
    doubleDoor: "双门",
    smoke: "单门+烟感",
    openDoorInPlace: "单门+开门到位",
    other: "其他",
    uModel: "U型",
    wired: "有线",
    networkFormat: "网络制式",
    sensor: "传感器",
    infraredType: "红外类型",
    elevatorSpeed: "电梯速度",
    carDoorType: "轿门类型",
    judgmentsNumber: "异常判断次数",
    ESDT: "ESDT网关",
    EMT: "EMT网关",
    NVR: "NVR网关",
    openDoorTime: "开门时间",
    horizontalSpacing: "平层间距",
    editBlack: "修改黑名单",
    editRoute: "修改网关路由",
    downloadingTemplate: "下载导入模板",
    blacklist: "黑名单",
    supportProtocol: "支持协议",
    bound: "未绑定",
    unbound: "已绑定",
    selectBlacklist: "请先在列表选择需要设置黑名单的网关设备",
    selectDevice: "请先在列表选择需要编辑的网关设备",
    selectSupportProtocol: "请先在列表选择需要设置支持协议的网关设备",
    labelWidth: "180px",
    editDevice: "编辑网关设备",
    import: "导入",
    dtuCode: "DTU编号",
    ip: "ip",
    deviceModel: "设备型号",
    signalType: "信号类型",
    simCode: "SIM卡号",
    simICCID: "ICCID",
    signalStrength: "信号强度",
    softVer: "软件版本",
    hardVer: "硬件版本",
    bindState: "绑定状态",
    temperature: "设备温度",
    devicePower: "设备电量",
    linkStatus: "主板链接状态",
    blackStatus: "黑名单",
    blackYes: "是",
    blackNo:"否",
    dtuStatus: "在线状态",
    expirationTime: "SIM卡到期时间",
    updateTime: "最后更新时间",
    liveSubscription: "实时订阅",
    liveStop: "实时停止",
    onlineElevator: "电梯在线同步",
    state: "状态",
    audio: "音频",
    inNetState: "入网状态",
    communicationFailed: "断开",
    communicationNormal: "正常",
    dtuOnline: "在线",
    call: "通话中",
    dtuOffline: "离线",
    onlineState: "在线状态",
    onlineUpdateTime: "最后在线时间",
    invalid: "无效",
    accessTime: "接入时间",
    deviceCard: "只能选择一条查看流量卡的网关设备",
    trafficCard: "请先在列表选择需要查看流量卡的网关设备",
    selectOne: "只能选择一条网关设备",
    deviceStatus: "只能选择一条查看状态的网关设备",
    checkDevice: "请先在列表选择需要查看状态的网关设备",
    no: "否",
    yes: "是",
  },

  // 急修工单
  workOrder: {
    workOrderType: "工单类型",
    alarmType: "报警类型",
    workOrderStatus: "工单状态",
    alarmTime: "报警时间",
    workOrderNo: "工单编号",
    orderCancel: "工单撤销",
    handleMethod: "处理方式",
    faultReason: "故障原因",
    orderConfirm: "工单确认",
    elevator: "电梯",
    faultDesc: "来电中描述故障情况",
    callRecord: "来电记录",
    alarmPeopleTel: "报警人电话",
    callTime: "来电时间",
    labelWidth: "90px",
    manualAlarm: "人工报警",
    automaticAlarm: "自动报警",
    deviceReasonTrapped: "人工-A1（设备原因困人）",
    deviceReasonSafe: "人工-A2（设备原因安全问题）",
    elevatorDamageOrFault: "人工-B（损坏停梯或存在故障隐患）",
    minorIssues: "人工-C（轻微问题）",
    userReasonNoTrapped: "人工-D1（用户原因未困人）",
    userReasonTrapped: "人工-D2（用户原因困人）",
    workOrder: "工单",
    information: "工单信息",
    alarmType_0: "故障停梯",
    alarmType_1: "电梯困人",
    alarmType_2: "警铃报警",
    alarmType_3: "严重故障",
    deviceCode: "设备编号",
    acceptor: "受理人",
    repairPeople: "接警人",
    repairMethod: "报修方式",
    selfRepair: "自动报警",
    appRepair: "App报警",
    phoneCall: "电话报修",
    inspection: "年检产生",
    contactPerson: "联系人",
    contactTel: "联系电话",
    servicePersonnel: "服务人员",
    hasPeople: "有人",
    noPeople: "无人",
    recordState_0: "报警",
    recordState_1: "接警",
    recordState_2: "到场",
    recordState_3: "完工",
    recordState_4: "恢复",
    recordState_5: "二次报警完工",
    recordState_6: "确认",
    recordState_7: "自动确认",
    recordState_8: "撤销",
    received: "接警",
    finished: "已结",
    completed: "完工",
    confirmed: "确认",
    canceled: "撤销",
    receive: "接警",
    finish: "已结",
    complete: "完工",
    confirm: "确认",
    cancel: "撤销",
    alarmReported: "已报警",
    notReported: "未报警",
    alarmed: "已接警",
    unAlarmed: "未接警",
    arrived: "已到场",
    notArrived: "未到场",
    finished_: "已完工",
    recovery: "已恢复",
    notFinished: "未完工",
    twoAlarmCompleted: "二次报警完工",
    confirmed_: "已确认",
    notConfirmed: "自动确认",
    autoConfirmed: "未确认",
    cancelled: "已撤销",
    baseInfo: "基本信息",
    alarmReportedBy: "报警人",
    emergencyRepairRecord: "急修记录",
    system: "系统",
    faultRecords: "故障记录",
    faultNo: "故障码",
    faultType: "故障分类",
    faultTime: "故障时间",
    mainBoardTime: "主板时间",
    faultStorey: "故障楼层",
    faultFloorCode: "故障楼层代码",
    faultFloor: "故障楼层",
    displayFaultFloor: "显示楼层",
    unKnowFault: "未知故障",
    elevatorInfo: "电梯信息",
    monitorElevator: "监控电梯",
    propertyInfo: "物业信息",
    revocationReason: "撤销原因",
    confirmPeople: "确认人",
    propertyName: "物业名称",
    hex: "十六进制",
    workorderInfoTrack: "工单信息跟踪",
    deviceInfo: "设备记录",
    status: "状态",
    onLine: "在线",
    offLine: "离线",
    createTime: "时间",
    launcher: "发射装置",
    rescueMap: "救援地图",
    currentLocation: "当前位置 >电梯急修",
    choiceDate: "合同结束日期需大于开始日期",
    tip: {
      stoped: "此工单流程已暂停，不能操作",
      cantRepeat: "不能重复添加急修人员",
      want: "确定",
      mark: "吗？",
    },
  },
  floorDisplay: {
    floorDisplay: "楼层对照",
    floorDisplayNo: "楼层模板编号",
    no: "编号",
    name: "名称",
    setting: "设置",
  },
  faultTemplate: {
    subCode: "子代码",
    isBuiltIn: "是否内置",
    editDefaultFaultTemplate: "编辑中文默认故障模板",
    editDefaultFaultTemplateEn: "编辑英文默认故障模板",
    faultTemplate: "故障模板",
    faultTemplateNo: "故障模板编号",
    no: "编号",
    name: "名称",
    mark: "标签",
    type: "类型",
    type1: "控制系统",
    type2: "变频器",
    baseInfo: "基本信息",
    faultCode: "故障代码",
    faultType: "故障分类",
    SerialNo: "序号",
    solution: "解决方案",
    maintReco: "保养建议",
    reason: "故障原因",
    addaRow: "添加一行",
    deleteSelectedRow: "删除选中行",
    generatingGeneralFaultCode: "生成通用故障代码",
    duplicateFaultCode: "存在重复的故障代码",
    remark: "备注",
  },
  developer: {
    developer: "开发者",
    no: "代码",
    name: "名称",
    appKey: "AppKey",
    appSecret: "AppSecret",
    contact: "联系人",
    mobile: "电话",
    address: "地址",
    status: "状态",
    auth: "权限",
    activate: "激活",
    close: "关闭",
    baseUrl: "接口基地址",
    type: "类型",
    baseAlarmUrl: "报警推送接口基地址",
    baseAlarmStatusUrl: "报警状态推送接口基地址",
    baseFaultUrl: "故障推送接口基地址",
    baseEventUrl: "事件推送接口基地址",
    baseTerminalUrl: "物联网设备状态推送基地址",
    information: "基本信息",
    Subscription: "订阅信息",
    subscriptionServiceList: {
      alarm: "报警",
      remove: "消除报警",
      repeal: "撤销报警",
      affair: "事件",
      fault: "故障",
    },
    tip: {
      no: "代码不能为空",
      name: "名称不能为空",
      mobile: "6~请输入正确的手机号",
      appSecret: "AppSecret长度为8个数字和字母组合",
      activateSucceed: "激活成功",
      closeSucceed: "关闭成功",
    },
  },
  developerDataAuthority: {
    developerDataAuthority: "数据权限",
    useUnitNo: "使用单位编号",
    useUnitName: "名称",
  },
  dtuTest: {
    tips: "提示",
    code: "DTU编号",
    ps: "备注",
    add: "增加DTU",
    frequency: "通过基准(次)/测试时间(分钟)",
    startTesting: "开始测试",
    countdown: "测试倒计时",
    second: "秒",
    retest: "重新测试",
    save: "保存",
    successCount: "成功次数",
    failedCount: "失败次数",
    tip: {
      message:
        "每次测试时间为5分钟，请耐心等待" +
        " 测试中请勿刷新或者关闭页面，否则测试将断，需重新开始。",
      tip1: "请填写dtu编号",
      tip2: "尚未找到该Dtu，正在重新查找",
      tip3: "测试时间不能大于24小时",
      tip4: "通过基准不填，或者填入正整数",
      tip5: "不能同时添加多条DTU",
      tip6: "最多只能添加20台DTU,请删除后再添加",
      tip7: "DTU必须是11位数字，如“20130113092”",
      tip8: "确定离开DTU测试吗?",
    },
  },
  // 健康指数
  potentialFault: {
    healthIndex: "健康指数",
    healthIndex_low: "低",
    healthIndex_middle: "中",
    healthIndex_high: "高",
    potentialFault: "电梯安全隐患",
    potentialFaultDetails: "安全隐患详情",
    faultOccurrenceTime: "故障发生时间",
    totalPoints: "总分",
    warningValue: "警戒值",
    faultCode: "故障代码",
    faultDesc: "故障描述",
    weight: "权重",
    faultCount: "故障次数",
    potentialFaultName: "隐患类型",
  },
  // 节目单
  programCard: {
    programCardName: "节目单名称",
    notice: "公告",
    companyName: "公司名称",
    notSupportVideo: "您的浏览器不支持Video标签",
    programCard: "节目单",
    edit: "编辑节目单",
    add: "新增节目单",
    prompt1: "* 支持图片(jpg/jpeg、png、gif)和视频类型(mp4)。",
    prompt2: "* 在选择框内按住Ctrl键单击鼠标进行多选。",
    prompt3: "* 单个视频大小不超过300M，单张图片大小不超过3M。",
    prompt4: "* 部分mp4视频由于编码问题，浏览器端无法预览，但屏幕上播放正常。",
    upload: "上传",
    program: "节目",
    sorting: "排序",
    imageFiles: "图片文件不能超过 3MB!",
    videoFiles: "视频文件不能超过 300MB!",
    fileType: "请上传允许的文件类型",
    uploadSuccessful: "上传成功，点击保存后生效",
  },
  // 智能摄像
  tpLink: {
    deviceStatus: "设备状态",
    deviceName: "监控设备名称",
    boundElevator: "已绑定电梯",
    deviceType: "设备类型",
    deviceModel: "设备型号",
    mac: "MAC地址",
    preview: "预览",
    playBack: "回放",
    alarm: "报警",
    peoples: "人数",
    synchronizationSucceeded: "同步成功",
    synchronizationFailed: "同步失败",
    alarmRecord: "报警记录",
    videoType: "摄像头型号",
    synchronization: "同步",
    alarmSetting: "报警转发设置",
    url: "转发地址",
    shiLian: "SL-IPC-XSD001/E216",
    tpLink: "TPLink",
    cameraReplace: "摄像头绑定在了另一部电梯上,更否更换?",
  },
  // 智能屏幕
  elevatorLcd: {
    elevatorLcd: "智能屏幕",
    programList: "节目单",
    videoUser: "视频用户",
    onLineStatus: "在线状态",
    elevatorNo: "电梯编号",
    elevatorName: "电梯名称",
    useUnitName: "使用单位",
    terminalID: "DTU编号",
    heartTime: "心跳时间",
    online: "在线",
    offline: "离线",
    currentProgram: "当前节目单",
    previewProgram: "预览节目单",
    controlSystem: "接入系统",
    replace: "该电梯已绑定其他终端，是否替换?",
    elevatorDtuCode: "DTU编号",
    tip: {
      lcdId: "mac不能重复",
      mac: "MAC必须为17位",
      password: "密码长度为6-20个数字和字母组合",
      elevator: "电梯不能为空",
    },
    elevatorRegisterNo: "设备代码",
    lcdId: "mac",
    password: "密码",
  },
  msgPushRecord: {
    messagePush: "消息推送",
    allMsgPush: "向所有用户推送消息",
    placeholder: "请输入消息内容,最多500字",
    messageId: "极光推送MessageID",
    msgType: "消息类型",
    content: "消息内容",
    username: "目标用户",
    createTime: "创建时间",
    allUser: "所有用户",
    tip: {
      pushSuccess: "推送消息成功",
      pushFailure: "推送消息失败",
    },
    common: "通用消息",
    alarmBell: "警铃报警",
    workOrder: "急修工单",
    powerLoss: "电源系统异常（停电）",
  },
  publicNotice: {
    more: "查看更多",
    noticeTitle: "标题",
    publishDate: "发布日期",
    status: "主页显示",
    name: "编辑人",
    noticeDesc: "内容",
    tip: {
      Title: "标题不能为空",
    },
  },
  parts: {
    bindingRecord: "绑定记录",
    labelWidth: "100px",
    no: "编号",
    name: "名称",
    partsTypeName: "配件类型",
    elevatorCompanyName: "整梯厂",
    partsSn: "配件eid",
    model: "型号",
    brand: "品牌",
    price: "价格",
    times: "使用次数",
    life: "期限",
    status: "状态",
    creatorName: "创建者",
    elevatorProductDescription: "备注",
    hot: "是否热门",
    tip: {
      no: "编号不能为空",
      name: "名称不能为空",
      partsSn: "配件Id必须为34个字符",
    },
    result: {
      band: "已绑定",
      noBand: "未绑定",
    },
  },
  partsType: {
    no: "编号",
    name: "名称",
    partsTypeSn: "类型序列号",
    desc: "配件描述",
    tip: {
      no: "编号不能为空",
    },
  },
  userLoginLog: {
    userName: "用户名",
    name: "员工姓名",
    startTime: "开始时间",
    endTime: "结束时间",
    loginType: "登录类型",
    loginIp: "登录IP",
    loginTime: "登录时间",
    web: "网页",
    app: "APP",
    wx: "微信小程序",
  },
  userOperateLog: {
    operateTime: "操作时间",
    userName: "User Name",
    name: "Name",
    startTime: "Start Time",
    endTime: "End Time",
    operateContent: "操作内容",
    operateLog: "操作日志",
    appOperateLog: "app操作日志",
  },
  uiSetting: {
    kanbanTitle:"看板标题",
    labelWidth: "70px",
    currentSetting: "当前设置",
    systemName: "系统名称",
    logoLarge: "大Logo",
    logoSmall: "小Logo",
    banner: "首页轮播图",
    appBanner: "app轮播图",
    companyName: "公司",
    faviconIcon: "标题栏图标",
    tip: {
      fileType: "上传图片只能是 jpg、png格式!",
      isLt2M: "上传图片大小不能超过 2MB!",
      pictures: "超出图片数量，请删除后再添加",
    },
  },
  userThirdPart: {
    video: "呼叫",
    loginId: "账号",
    userType: "用户类型",
    account: "环信账号",
    password: "密码",
    choose: "选择账号",
    tip: {
      account: "环信账号不能为空",
      password: "密码长度为6到20个字母和数字的组合",
    },
  },
  useUnit: {
    no: "编号",
    name: "名称",
    unitCode: "信用代码",
    type: "类型",
    propertyComId: "所属物业",
    buildingCount: "楼数量",
    districtId: "地区",
    address: "详细地址",
    coordinate: "经纬度",
    lng: "经度",
    lat: "纬度",
    person: "联系人",
    contactTel: "手机号码",
    note: "备注",
    tip: {
      useUnitName: "请输使用单位名称",
      name: "名称不能为空",
      propertyComId: "所属物业不能为空",
      districtId: "地区不能为空",
      lng: "经度不能为空",
      lat: "纬度不能为空",
    },
  },

  part: {
    addChild: "添加子级",
    exportAuthenticated: "导出已认证",
    export: "导出列表",
  },
  company: {
    installPhone: "安装单位联系电话",
    useUnit: "使用单位",
    manufacturerCompany: "制造单位",
    propertyCompany: "物业单位",
    maintenanceCompany: "维保单位",
    installationCompany: "安装单位",
    modificationCompany: "改造单位",
    add: "+ 新增",
    belongingCompany: "所属企业",
    company: "公司",
    tenant: "租户",
    addUser: "新增用户",
    no: "企业编号",
    name: "企业名称",
    abbreviation: "企业简称",
    elevCount: "电梯数量",
    userCount: "用户数量",
    externalNo: "外部编号",
    brand: "品牌",
    type: "企业类型",
    parentId: "上级企业",
    person: "联系人",
    phone: "联系电话",
    factoryNo1: "厂家编号",
    dedicatedCode: "企业专用码",
    unifiedSocialCreditCode: "统一社会信用代码",
    tip: {
      no: "机构编号不能为空",
      name: "名称不能为空",
    },
  },
  quantity: {
    no: "机构编号",
    name: "机构名称",
    abbreviation: "机构简称",
    districtId: "行政区域",
    address: "机构地址",
    tip: {
      no: "机构编号不能为空",
      name: "名称不能为空",
      districtId: "行政区域不能为空",
      address: "机构地址不能为空",
    },
  },
  role: {
    role: "角色",
    auth: "权限列表",
    name: "名称",
    isDefault: "默认角色",
    orgName: "企业名称",
    tip: {
      name: "角色名称不能为空",
    },
  },
  device: {
    second: "秒",
    min:"分",
    exportExcel: "导出",
    code: "编号",
    dtuNum: "DTU数量",
    nodeNum: "节点数量",
    onlineNode: "在线节点",
    status: "状态",
    online: "在线",
    offline: "离线",
    port: "端口",
    video: "视频",
    softwareVersion: "软件版本",
    hardwareVersion: "硬件版本",
    signalIntensity: "信号强度",
    temperature: "温度",
    electricQuantity: "电量",
    setUp: "设置",
    read: "读取",
    APNName: "APN名称",
    userName: "用户名",
    password: "密码",
    all: "全部",
    refresh: "刷新",
    simCard: "流量卡",
    record: "在线记录",
    upgrade: "升级",
    monitor: "监控",
    expandAll: "全部展开",
    collapseAll: "全部折叠",
    upgradeConfirm: "确认执行升级吗？",
    iccidIsEmpty: "iccid为空",
    offlineDuration: "离线持续时间",
    within2Hours: "2小时以内",
    more2hoursLess1Day: "超过2小时且未超过1天",
    moreThan1Days: "超过1天",
    custom: "自定义",
    minute: "分钟",
    hour: "小时",
    day: "天",
    illegalType: "输入不合法",
    cannotBeGreater: "开始时间不能大于等于结束时间",
    pleaseEnter: "请输入查询时间",
    intercom: "对讲设置",
    serverAddress: "服务器地址",
    callNos: "呼叫号码",
    dutyRoom: "值班室",
    volume: "音量",
    inFilter: "对讲过滤",
    inFilterOpen: "开启",
    inFilterClose: "关闭",
    account: "SIP账号",
    readLoadingTxt: "参数读取中,请耐心等待",
    setLoadingTxt: "参数写入中,请耐心等待",
    readSuccess: "参数读取成功",
    readFail: "参数读取失败",
    writeSuccess: "参数设置成功",
    writeFail: "参数设置失败",
    onlineRate: "电梯在线率",
  },
  mb: {
    changeType: "操作内容",
    changeTime: "操作时间",
    eventName: "事件",
  },
  oldMaintWorkOrder: {
    confirmed: "已确认",
    wait: "待确认",
    invalid: "无效",
  },
  msgSend: {
    phoneNo: "发送手机号",
    content: "发送内容",
    sendTime: "发送时间",
  },
  msgTemplate: {
    name: "名称",
    content: "消息内容",
    supportVars: "可用参数",
  },
  userDebugInfo: {
    userDebugInfo: "调试人员",
    userName: "用户",
    debugLevel: "调试级别",
    passwordExpirationDate: "调试密码到期时间",
    contractNOs: "合同号",
    tip: {
      placeholder: "多个合同号请用英文逗号隔开 ",
      userId: "请选择用户",
      debugLevel: "请选择调试级别",
      passwordExpirationDate: "请选择密码到期日",
      contractNOs: "请输入合同号",
    },
  },
  potentialFaultSetting: {
    potentialFaultSetting: "安全隐患设置",
    name: "名称",
    warningValue: "警戒值",
    statPeriod: "持续周期（天）",
    note: "备注",
    faultTemplate: "故障模板",
    baseInfo: "基本信息",
    details: "详情",
    faultCode: "故障编号",
    weight: "权重",
    operate: "执行安全隐患统计",
    tip: {
      name: "请输入名称",
      warningValue: "警戒值大于0",
      statPeriod: "持续周期大于0",
      faultTemplate: "请选择故障模板",
      faultCode: "请填写数字大于0",
      weight: "请填写数字0-100",
      operateSuccess: "执行成功",
      operateError: "执行失败",
    },
  },
  sysItem: {
    name: "类型",
    value: "值",
    status: "开关状态",
    open: "ON",
    close: "OFF",
  },
  gatewayVersion: {
    gatewayVersion: "网关程序版本",
    sofewareName: "软件名称",
    sofewareVersionID: "软件版本标识",
    sofewareVersion: "软件版本",
    status: "状态",
    isFullAdapter: "是否设备全适配",
    note: "版本描述信息",
    upload: "上传软件",
    click: "点击上传",
    md5: "软件MD5",
    releaseDate: "发布日期",
    creator: "创建人",
    yes: "是",
    no: "否",
  },
  elevatorUser: {
    elevatorUser: "电梯用户关系维护",
    selectLift: "选择电梯",
    selectUser: "选择用户",
    elevatorNotSelected: "未选择电梯",
    userNotSelected: "未选择用户",
  },
  thirdPartyPlatform: {
    name: "名称",
    note: "描述",
    set: "设置",
    companyName: "企业名",
    account: "账号",
    password: "密码",
  },
  vibrationData: {
    speedMax:"速度Max",
    speedMin:"速度Min",
    scanCheck:"请使用电梯云APP扫码查看",
    moreVib:"查看更多相关曲线",
    idNo: "ID号",
    elevatorName: "电梯名称",
    createTime: "创建时间",
    runState: "运行状态",
    dataDesc: "备注",
    downLoad: "下载",
    curve: "曲线",
    tab1: "下载列表",
    tab2: "操作器",
    collectTime: "采样时间",
    collectFrequency: "采样频率",
    collectType: "采集类型",
    calibration: "校准",
    eocdParamsSet: "参数设置",
    searchEOCDData: "查询EOCD数据",
    startCollect: "手动开始采集",
    endCollect: "手动停止采集",
    clearCollect: "清空采集记录",
    cancelCollect: "撤销采集",
    parTotalSwitch: "轿顶EOCD总开关",
    parCollectSwitch: "轿顶EOCD自动采集开关",
    parCollectCycle: "自动采集周期",
    parCollectFrequency: "自动采集频率",
    acquisitionParameters: "采集参数",
    autoUpload: "自动上传",
    doorCollectSwitch: "开关门自动采集开关",
    doorCollectCycle: "开关门自动采集周期",
    alarmFileSwitch: "报警文件自动上传",
    safetyHazardSwitch: "安全隐患报警开关",
    safetyHazardRecord: "安全隐患报警数据记录",
    read: "读取",
    set: "设置",
    dtuNoOnline: "终端不在线,不支持该操作",
  },
  alarm: {
    id: "报警id",
    mid: "设备序列号",
    code: "报警类型",
    value: "识别数量",
    description: "报警描述",
    picUrl: "图片",
    videoUrl: "视频",
    uploadTime: "报警时间",
    fileType: "文件类型",
    filePath: "文件路径",
    imageOrVideo: "图片/视频",
  },
  // 合同管理
  contract: {
    packagingForm: "包料形式",
    contractSource: "合同来源",
    expiredStatus: "到期情况",
    contractStartDate: "合同起日",
    contractEndDate: "合同止日",
    copy: "复制",
    batchDelete: "批量作废",
    appendix: "附件",
    importContract: "导入合同",
    contractNo: "合同号",
    siteName: "现场名称",
    remindExpired: "提醒到期",
    beExpired: "已到期",
    expiresMonth: "一个月内到期",
    expiresThreeMonths: "三个月内到期",
    contractPrice: "合同价款(元)",
    selectOneContract: "请至少选择一项合同",
    voidedContract: "确认作废已选合同吗，已作废的合同不可恢复！",
    firstSelectContract: "请先选择一项合同",
    mostOneContract: "最多选择一项合同",
    elevatorSelect: "已选电梯 ",
    tower: " 台：",
    inspectionUndertaker: "年检承担方",
    speedLimiterUndertaker: "限速器承担方",
    brakeTestFeeUndertaker: "制动试验费用承担方",
    freeAccessoriesQuota: "免费配件额度(元)",
    paymentMethod: "合同付款方式",
    monthlyUnitPrice: "月单价(元)",
    agencyFees: "代理费(元)",
    contactInformation: "联系方式",
    isSubcontract: "是否分包",
    check: "查看",
    contractInfo: "合同相关信息",
    fileInfo: "附件信息",
    addFile: "添加附件",
    fileName: "附件名称",
    uploadTime: "上传时间",
    uploader: "上传人",
    upload: "上传",
    uploadTypeLimit:
      "只能上传后缀名为jpg、png、bmp、jpeg、xlsx、xls、docx、doc的文件",
    uploadSizeLimit: "上传文件大小不能超过10M",
    uploadSuccess: "上传成功",
    uploadFailure: "上传失败",
    download: "下载",
    delete: "删除",
  },

  newMsgSend: {
    referBizNo: "关联单号",
    type: "消息类型",
    msgPush: "APP消息推送",
    sms: "手机短信",
    email: "邮件",
    wxMsgPush: "小程序消息推送",
    content: "消息内容",
    target: "目标客户",
    status: "发送状态",
    sendSucceed: "发送成功",
    failSend: "发送失败",
    sendTime: "发送时间",
  },

  // 日周月管理
  dayWeekMonthManger: {
    filter: "关键词检索",
    status: "状态",
    isAbnormal: "是否异常",
    abnormal: "异常",
    notAbnormal: "未见异常",
    number: "编号",
    elevatorNo: "电梯编号",
    elevatorName: "电梯名称",
    useUnit: "使用单位",
    controlResult: "管控结果",
    controlDate: "管控日期",
    checkResult: "排查结果",
    dispatchResult: "调度结果",
    checkCycle: "排查周期",
    to: " 至 ",
    createTime: "创建时间",
    creator: "创建人",
    operate: "操作",
    edit: "编辑",
    delete: "删除",
    view: "查看",
    anomalies: "项异常",
    ok: "确定",
    cancel: "取消",
    index: "序号",
    type: "类别",
    controlItems: "日管控项目",
    checkItems: "周排查项目",
    dispatchItems: "月调度项目",
    handleResult: "处理结果",
    count: "开通台数",
    dispatchCycle: "调度周期",
    templateNo: "模板编号",
    templateName: "模板名称",
    enterprise: "所属企业",
    isDefault: "是否默认",
    showAll: "全部显示",
    hideItems: "隐藏的项目",
    show: "显示",
    projectType: "项目类型",
    projectName: "项目名称",
    precaution: "采取的防护措施",
    safetyOfficerOpinion: "安全管理员意见",
    safetyDirectorOpinion: "安全总监意见",
    risk: "上周安全风险隐患问题整改核实情况",
    rectification: "本周主要安全风险隐患和整改情况",
    safetyManagementEvaluation: "本周安全管理情况评价",
    workFocus: "下周工作重点",
    mainProblem: "本月巡查主要问题",
    rectification1: "本月整改方案落实情况",
    unresolvedProblem: "本月还未解决的问题",
    scheduleContent: "月调度相关内容（如制度修订、人员岗位职责变化等）",
    safetyContent: "其他安全事项",
    precaution1: "会议研究采取的措施",
    tip: {
      confirmDelete: "确认删除吗？",
      deleteSuccess: "删除成功",
      deleteError: "删除失败",
      handleResult: "请输入处理结果",
    },
  },
  // 事件管理
  event: {
    directElevator: "直梯",
    escalator: "扶梯",
    newEvent: "新事件",
    elevatorDPC: "电瓶车入梯",
    dtuNumber: "DTU编号",
    faultRecoverTime: "故障恢复时间",
    faultSn:"故障记录编号",
    warning:"提示：请输入DTU编号，否则查询结果为空。",
    filter: "关键字检索",
    occurrenceTime: "发生时间",
    export: "导出",
    elevatorNo: "电梯编号",
    elevatorName: "电梯名称",
    node: "节点",
    useUnit: "使用单位",
    gatewayPowerDown: "主电源断电",
    nonGateAreaStop: "非门区停止",
    alarmBell: "警铃报警",
    enterStopService: "进入停止服务",
    gatewayPowerFailure: "主电源掉电",
    repair: "检修",
    event: "事件",
    data: "数据",
    alarmTime: "报警时间",
    picture: "图片",
    alarmType: "报警类型",
    powerDownTime: "掉电时间",
    registerCode: "设备代码",
    whetherToRestore: "是否恢复",
    fileName: "主电源断电.xlsx",
    callRecord: "通话记录",
    alarmFileName: "警铃报警.xlsx",
    workOrderFileName: "急修工单.xlsx",
    callTime: "通话时间",
    callDuration: "通话时长",
    originator: "呼叫对象",
    connector: "接通对象",
    phone: "接通号码",
    reset: "复位",
    audio: "音频",
    playAudio: "播放音频",
    faultType: "故障分类",
    faultTime: "故障时间",
    boardFaultTime: "主板时间",
    faultNo: "故障码",
    faultFloorCode: "故障楼层代码",
    faultFloor: "故障楼层",
    displayFaultFloor: "显示楼层",
    faultRecordFileName: "故障记录.xlsx",
    safetyHazardTime: "安全隐患时间",
    safetyHazardCode: "安全隐患代码",
    safetyHazardDesc: "安全隐患描述",
    startFloor: "开始楼层",
    endFloor: "结束楼层",
    runningDistance: "运行距离(m)",
    maxSpeed: "最大速度(m/s)",
    maxAcceleration: "最大加速度(m/s2)",
    maxVariableAcceleration: "最大变加速度(m/s3)",
    recordTime: "记录时间",
    callElevatorStatistics: "呼梯统计",
    username: "用户名",
    registerMobile: "注册手机",
    implementationElevator: "执行电梯",
    userLocation: "用户位置",
    currentFloor: "当前楼层",
    destinationFloor: "目的楼层",
    operationType: "操作类型",
    remark: "备注",
    externalCallUp: "外召上",
    externalCallDown: "外召下",
    carCall: "轿内呼梯",
    callElevatorTimes: "呼梯次数",
    tip: {
      fillThreeDays: "请填写发生时间（三天之内）",
      fillSevenDays: "请填写掉电时间（七天之内）",
      fillFifteenDays: "请填写十五天之内的时间！",
      fillMonthDays: "请填写一个月之内的时间！",
      alarmTime: "请填写报警时间（七天之内）",
      faultTime: "请填写故障时间（半年之内）",
    },
  },
  // 电梯监控
  monitor: {
    change: "切换地图",
    mute: "静音",
    area: "地区",
    escalatorMonitor: "扶梯监控",
    escalatorConnect: "连接中...",
    error: "出错",
    open: "展开",
    close: "收起",
    buildNum: "楼号",
    ladderNum: "梯号",
    runningSpeed: "运行速度",
    retry: "重试",
    videoOnConnect: "正在连接，请稍后...",
    videoConnectFailed: "连接失败，查看是否安装插件...",
    a08Describe: "监控数据超时",
    a10Describe: "主板离线",
    a11Describe: "网关离线",
    a06Describe: "缓存中未找到该电梯",
    a05Describe: "排队数量",
    connectionDisconnection: "连接断开",
    currentUseUnit: "当前小区",
    elevatorMonitor: "监控",
    onlineStatus: "在线状态",
    online: "在线",
    offline: "离线",
    village: "Village name",
    monitoring: "多梯监控",
    monitor: "更多监控",
    monitorIBE: "ITE IOT",
    monitorA4Io: "输入输出点",
    information: "主要信息",
    liveData: "变频器实时数据",
    check: "下次年检时间",
    maintenance: "下次维保时间",
    transducer: "变频器系列",
    controlPanel: "控制板软件版本",
    unControlPanel: "控制板软件非标版本",
    operationPanel: "操作面板软件版本",
    customerNumber: "客户定制系列号",
    selection: "电机与控制方式选择",
    inverterStatus: "变频器状态",
    setFrequency: "设定频率",
    OutputFrequency: "输出频率",
    OutputVoltage: "输出电压",
    OutputCurrent: "输出电流",
    DCBusVoltage: "直流母线电压",
    PowerOnTimeAccumulation: "通电时间累计",
    RunningTime: "运行时间累计",
    FaultCode: "变频器故障代码",
    workingTime: "扶梯运行时间",
    faultTime: "故障发生时间",
    elevator: "电梯概况",
    state: "门锁状态",
    lock: "锁  梯",
    goUp: "上  行",
    goDown: "下  行",
    record: "操作记录",
    faultCode:"发生故障-代码",
    speedPro:"超速保护",
    nonPro:"非操纵逆转保护",
    stepPro:"梯级或踏板缺失保护",
    driveBorken:"驱动链断裂或伸长",
    combBlock:"梳齿板异物卡阻",
    stepSagg:"梯级或踏板下陷",
    emergStop:"紧急停止开关动作",
    status: {
      reConnection:"重连",
      switchElevator:"切换电梯",
      callUp:"上召",
      callDown:"下召",
      carCall:"轿内",
      faultReset: "故障复位",
      open: "开 门",
      close: "关 门",
      motion: "自动",
      service: "检修",
      fireReturn: "消防返回",
      fire: "消防员",
      emergencyPower: "应急电源",
      earthquakeModel: "地震模式",
      unKnow: "未知",
      safety: "安全回路断",
      fault: "故障",
      Lock: "锁梯",
      independence: "独立",
      driver: "司机",
      parallelControl: "并联/群控",
      overload: "超载",
      loaded: "满载",
      serviceable: "电梯可服务",
      mainPowerOff: "电源系统异常（停电）",
      openButton: "开门按钮",
      passengerStatus: "轿厢有人",
      instructionSettings: "指令设置",
      touchpadActions: "安全触板动作次数",
      curtainActions: "光幕动作次数",
      lighting: "轿内照明点灯次数",
      lightingTime: "轿内照明点灯时间",
      downwardRelevelingTimes: "上行再平层次数",
      upwardRelevelingTimes: "下行再平层次数",
      runSpeed: "运行速度",
      runningDistance: "电梯运行距离",
      stationLayer: "基站层开门次数",
      numberOfDoorOpenings: "门开次数",
      IcCardAuthority: "IC卡权限",
      setting: "设置",
      networkControl: "联网控制",
      remoteLockElevator: "远程锁梯",
      currentSettings: "读取当前设置",
      physicalFloor: "物理楼层",
      showFloor: "显示楼层",
      elevatorCarCommand: "轿内指令",
      inExecution: "正在执行中",
      allClosed: "全关",
      fullyOpen: "全开",
      hallInstruction: "厅外指令",
      cancel: "取消",
      saveSettings: "保存设置",
      gateArea: "开锁区域",
      openDoorInPlace: "开门到位",
      closeTheDoorInPlace: "关门到位",
      isAnyone: "轿内有人",
      currentService: "当前服务模式未知",
      doorCircuit: "门锁回路",
      stopsOutside: "轿厢在开锁区域外停止",
      duringOperation: "运行中开门",
      rushTop: "冲顶",
      squatBottom: "蹲底",
      speeding: "超速",
      unexpectedMovement: "轿厢意外移动",
      safetyCircuitBreak: "安全回路断路",
      circuitMalfunction: "层门锁回路故障",
      circuitFault: "轿门锁回路故障",
      controlDevice: "电梯控制装置故障",
      machineFailure: "电梯曳引机故障",
      inverterFailure: "变频器故障",
      systemFailure: "电梯制动系统故障",
      shortCircuit: "门锁电气装置短接",
      levelingInductionFault: "平层感应故障",
      floorLocationLost: "楼层位置丢失",
      limiterAction: "电动机运转时间限制器动作",
      closeDoorFault: "关门故障",
      openDoorFault: "开门故障",
      emergencyStopFault: "急停",

    },
    parameter: "运行参数",
    parameters: {
      times: "电梯运行次数",
      time: "电梯运行时间",
      wire: "钢丝绳运行弯折次数",
      openTimes: "电梯开关门次数",
    },
    mark: {
      position: "当前位置",
    },
    fault: {
      fault1: "正常运行",
      fault2: "故障困人",
      fault3: "断线失联",
    },
    elevatorName: "电梯名称",
    alarmType: "报警类型",
    continueTime: "持续时间",
    operation: "操作",
    elevatorStop: "故障停梯",
    elevatorStranded: "电梯困人",
    alarmBellsAlarm: "警铃报警",
    tip: {
      confirmReset: "确定复位吗?",
      faultResetFail: "故障复位失败",
      faultResetSuccess: "故障复位成功",
    }
  },
  yearCheck: {
    // labelWidth: "100px",
    expired: "过期未年检",
    notice: "年检提醒",
    all: "全部",
    yearCheckNo: "年检编号",
    date: "选择日期",
    checkDate: "检验日期",
    rectificationDate: "预计整改日期",
    note:"备注",
    file:"年检文件",

    status: "检验结果",
    // note: "备注",
    result: {
      pass: "通过",
      noPass: "不通过",
    },
    tip: "请选择电梯",
  },
  maintenanceArea: {
    machineRoom: "机房",
    bridge: "轿厢",
    layer: "层站",
    wellRoadAndTheBottomPit: "井道及底坑",
    escalator: "扶梯",
    other: "其他",
  },
  // 维保管理
  maintWorkOrder: {
    completed: "已完成",
    filter: "关键字检索",
    maintType: "保养类型",
    maintItem: "保养项目",
    maintSetting: "保养设置",
    branchAgency: "分支机构",
    safetySpotCheck: "安全抽查",
    productTypeName: "梯种",
    maintTypeName: "保养类型名称",
    elevatorType1: "曳引驱动乘客电梯",
    elevatorType2: "曳引驱动载货电梯",
    elevatorType3: "强制驱动载货电梯",
    elevatorType4: "液压乘客电梯",
    elevatorType5: "液压载货电梯",
    elevatorType6: "自动扶梯",
    elevatorType7: "自动人行道",
    elevatorType8: "防爆电梯",
    elevatorType9: "消防员电梯",
    elevatorType10: "杂物电梯",
    selectMaintItem: "选择保养类型项目",
    maintenanceProjectName: "保养项目名称",
    maintContent: "保养内容",
    maintRequire: "保养要求",
    maintArea: "保养区域",
    branchName: "分支名称",
    salesOrderNo: "订单号",
    phone: "电话",
    currentLocation: "当前位置",
    contacts: "联系人",
    featureDetails: "特性详情",
    featureNumber: "特性编号",
    featureName: "特性名",
    featureValue: "特性值",
    completionStatus: "完成状态",
    rectificationStatus: "整改状态",
    spotCheckResults: "抽查结果",
    spotCheckNumber: "抽查编号",
    cegion: "区域",
    branch: "分公司",
    projectName: "项目名称",
    maintenanceCompany: "维保公司",
    elevatorName: "电梯名称",
    deviceNumber: "设备号",
    maintenanceBySelf: "自保",
    subcontract: "分包",
    spotCheckInspector: "抽查人员",
    maintenanceWorker: "维保人员",
    spotCheckDate: "抽查日期",
    spotCheckInformation: "抽查信息",
    unrectified: "未整改",
    rectified: "已整改",
    elevator: "电梯",
    specification: "型号",
    totalScore: "总分",
    maintenanceQuality: "维保质量",
    basicConditions: "基本条件",
    checkContent: "检查内容",
    confirmProject: "确认项目",
    safety: "安全",
    category: "类别",
    completionTime: "完成时间",
    isQualified: "是否合格",
    signToConfirm: "签字确认",
    spotCheckInspectorConfrim: "抽查人员确认",
    maintenanceWorkerConfirm: "维保人员确认",
    type: {
      halfMonth: "半月保",
      month: "季度保",
      halfYear: "半年保",
      year: "年度保",
    },
    maintenanceArea: {
      machineRoom: "机房",
      bridge: "轿厢",
      layer: "层站",
      wellRoadAndTheBottomPit: "井道及底坑",
      escalator: "扶梯",
      other: "其他",
    },
    setting: {
      autoSendDay: "保养工单自动发送时间：提前",
      maintDistance: "维保作业距离：",
      meter: "米",
      isAutoGenerate: "是否自动生成保养工单：",
      generateInitialTime: "自动生成工单使用的初始时间：",
      completeDate: "完成时间",
      planDate: "计划时间",
      maintCycleDay: "保养周期天数：",
      day: "天",
      signInMethod: "作业签到方式：",
      photo: "拍照",
      isSignInApp: "是否允许客户在维保人员的APP端进行签字：",
      isOperationOverdue: "保养工单超期后，是否可继续操作：",
      isTwoMaintainerCheckIn: "是否允许两个维保人员均可进行工单签到：",
      isPaperMaintPhotoRequired: "是否要求必须上传纸质保养单照片：",
      fieldWorkMethods: "现场作业方式：",
      default:
        "默认：现场作业除'签到'和'完成'操作外，作业中不限制其他操作必须完成",
      custom: "自定义",
      isPhotoCheckIn: "必须拍照签到",
      isMaintainerMustSignInApp: "维保人员完成工单前必须在App上进行签字",
      isPhotoAbnormal: "对异常的保养项目，必须拍照",
      isSoundRecordAbnormal: "对异常的保养项目，必须录音",
      isContextAbnormal: "对异常的保养项目，必须填写文字描述",
      isPhotoNormal: "对正常的保养项目，必须拍照",
      isSoundRecordNormal: "对正常的保养项目，必须录音",
      isContextNormal: "对正常的保养项目，必须填写文字描述",
    },
    tip: {
      getMaintTypeItemError: "获取保养类型项目失败",
      maintTypeName: "保养类型名称不能为空",
      elevatorProductType: "梯种不能为空",
      maintTypeClassify: "保养类型分类不能为空",
      getElevatorProductTypeError: "获取电梯梯种失败",
      itemName: "保养项目名称不能为空",
      maintContent: "保养内容不能为空",
      maintRequire: "保养要求不能为空",
      maintArea: "保养区域不能为空",
      sendTip: "请输入一个介于0和7之间的值，0值代表不自动发送",
      distanceTip: "请输入一个大于0的值",
      cycleTip: "请输入一个介于1和90之间的值",
      score: "请给每一项打分",
      elevator: "请选择电梯",
      date: "维保日期不能为空",
      add: "不能重复添加电梯",
      confirm: "是否确认工单",
      invalidSuccess: "作废成功",
      invalidError: "作废失败",
      emptySingleRow: "请先选择一条记录",
      emptyMultipleRow: "请选择至少一条记录",
      confirmSend: "确认发送已选工单？",
      confirmInvalid: "确认作废已选电梯保养计划吗，已作废的保养计划不可恢复！",
      confirmDelete: "确认删除已选记录吗，已删除的记录不可恢复！",
      elevatorNo: "电梯编号不能为空",
      maintType: "保养类型不能为空",
      emptyMaintType: "请选择保养类型",
      planStartDate: "计划开始日期不能为空",
      planEndDate: "计划结束日期不能为空",
      maintCycle: "请输入一个大于0的数字",
      emptyPlan: "新增保养计划不能为空！",
      timeError: "开始时间需大于结束时间",
      timeError1: "当前日期间隔超出保养周期",
      invalidReason: "作废原因不能为空",
    },
    condition: {
      notProcessed: "未处理",
      noSuchItem: "无此项",
      qualified: "合格",
      qualifiedAfterAdjustment: "调整后合格",
      unqualified: "不合格",
    },

    customerSignature: "客户签字",
    dissatisfied: "不满意",
    commonly: "一般",
    satisfied: "满意",
    verySatisfied: "非常满意",
    evaluation: "保养质量服务评价：",
    sigAndReturnVisit: "客户签字及回访",
    uploadPicture: "上传现场照片",
    treatmentProcess: "处理过程",

    workCondition: "工作情况",
    invalidReason: "作废原因",

    generatePlan: "生成计划",
    checkMaintItem: "查看保养项目",
    selectElevator: "选择电梯带入",
    plan: {
      planStartTime: "计划开始日期",
      planEndTime: "计划结束日期",
      no: "无",
      have: "有",
      row: " 条：",
      selected: "已选电梯（可添加计划）",
      elevator: "电梯信息",
      maintCycle: "保养周期",
    },

    situationDescription: "现场情况描述",
    picture: "现场情况照片",
    soundRecord: "现场情况录音",
    processTime: "处理时间",
    maintBaseInfo: "工单信息",
    created: "已创建",

    haveMaintPlan: "有无保养计划",
    recentNotAccept: "最后24小时未接受",
    expired: "过期未保养",
    maintWorkOrder: "保养工单",
    maintainerName1: "维保工1",
    maintainerName2: "维保工2",
    creator: "建单人",
    invalid: "作废",
    normal: "正常",
    advance: "提前",
    overdue: "超期",
    notAccepted: "未接受",
    accepted: "已接受",
    signedIn: "已签到",
    notSignIn: "未签到",
    propertyCom: "物业单位",
    maintDate: "保养日期",
    createTime: "创建时间",
    maintDuration: "保养时长",
    all: "全部",
    send: "发送",
    completionSituation: "完成情况",
    maintPlan: "保养计划",
    planDate: "计划保养日期",
    orderNum: "工单编号",
    maintStatus: "状态",
    maintDevice: "保养建议",
    no: "工单编号",
    notSend: "未发送",
    company: "维保公司",
    date: "计划维保日期",
    status: "状态",
    State: "状态改变",
    ultraTime: "超期",
    hasExtended: "已超期",
    inPlan: "计划中",
    execution: "执行中",
    stocks: "已完成",
    confirmed: "已确认",
    affirm: "确认",
    content: "维保内容",
    userEvaluation: "用户评价",
    property: "物业签名",
    maintenance: "维保工签名",
    satisfaction: "满意度",
    advice: "意见",
    returnRecord: "回访记录",
    dateList: {
      plan: "维保计划日期",
      start: "维保开始日期",
      completion: "维保完成日期",
      confirmation: "维保确认日期",
    },

    maintenanceStatus: {
      free: "无需保养",
      have: "已经保养",
      need: "需要更换",
    },

    clearSelection: "清空多选",
  },

  ota: {
    applyDetails: "申请明细",
    operateTime: "操作时间",
    balance: "余额",
    apply: "申请",
    burn: "烧录",
    type: "类型",
    operateRecord: "操作记录",
    burningTimes: "可烧录次数",
    name: "姓名",
    account: "账户",
    closure: "关闭",
    pass: "通过",
    reject: "拒绝",
    rejectPeople: "拒绝人",
    rejectReason: "审批意见",
    rejectTime: "拒绝时间",
    applyProgram: "申请程序",
    applicant: "申请人",
    applicantAccount: "申请人账号",
    applicantName: "申请人姓名",
    applicationTime: "申请时间",
    approver: "审批人",
    approverTime: "审批时间",
    approverAccount: "审批人账号",
    approverName: "审批人账号姓名",
    details: "明细",
    programList: "",
    applyList: "申请清单",
    user: "用户",
    isEffective: "是否锁定",
    locking: "锁定",
    effective: "有效",
    program: "程序名",
    count: "次数",
    updateTime: "更新时间",
    applyNumber: "申请次数",
    remainingNumber: "剩余次数",
    note: "申请备注",
    numberNotNull: "操作次数不能为空",
    applyNumberMore: "申请次数不能大于剩余次数",
    approvalStatus: "审批状态",
    applying: "申请中",
    approved: "已同意",
    rejected: "已拒绝",
    endTime: "结束时间",
    currentNumber: "当前可烧录次数",
    ownedCompany: "所属企业",
  },

  // 公共
  common: {
    new:"新建",
    newLevel:"新建一级",
    selectService:"请选择服务人员",
    selectTenant:"选择租户",
    keyWord:"输入关键字进行过滤",
    selectType:"选择类型",
    AccountStat:"账号状态",
    Enable:"启用",
    Disable:"禁用",
    AnnualInspection: "年检",
    laddering: "工业上梯",
    sevenTime: "请填写两个月之内的时间！",
    more1: "更多",
    generation: "生成",
    add: "新增",
    filter: "关键字检索",
    remark: "备注",
    isDefault: "是否默认",
    search: "查询",
    reset: "重置",
    operate: "操作",
    operation: "操作",
    edit: "编辑",
    delete: "删除",
    yes: "是",
    no: "否",
    index: "序号",
    remove: "移除",
    cancel: "取消",
    save: "保存",
    createTime: "创建时间",
    creator: "创建人",
    isDelete: "确定删除吗",
    tips: "提示",
    clear: "清空选择",
    confirmSelect: "确定选择",
    status: "状态",
    submit: "已提交",
    notSubmit: "未提交",
    export: "导出",
    startDate: "开始日期",
    to: "至",
    endDate: "结束日期",
    completed: "已完成",
    notCompleted: "未完成",
    view: "查看",
    confirm: "确定",
    pleaseEnter: "请输入",
    pleaseSelect: "请选择",
    draft: "草稿",
    result: "结果",
    tip: {
      deleteSuccess: "删除成功",
      deleteError: "删除失败",
      baiduMapError: "百度地图加载失败",
      googleMapError: "可能由于网络问题，无法加载谷歌地图",
      getDataError: "数据获取失败",
      notNull: "不能为空",
      needNum: "请输入大于0的数字",
      needNum1: "请输入 >=0 的数字",
      saveSuccess: "保存成功",
      optional: "出厂编号与电梯设备代码至少填一项！",
      completeFloorInformation: "请完善楼层信息",
      saveError: "保存失败",
      settingSuccess: "设置成功",
      addSuccess: "添加成功",
      addError: "添加失败",
      submitError: "请修改后提交",
      operationSuccess: "操作成功",
      operationFailure: "操作失败",

      comSuccess:"发送指令成功",
      lockSuccess:"发送锁梯指令成功",
      setupFailed:"设置指令失败",
      setupSucceed:"设置指令成功",
      getDataFailed:"获取数据失败",
      sentSuccess:"发送读取指令成功",
      getSetFailed:"获取当前设置失败！",
      saveSetSuccess:"保存当前设置成功！",
      saveSetFailed:"保存当前设置失败！",
      sentSaveSuccess:"发送保存指令成功",
      floorNotConfig:"当前楼层未配置",
      floorHigher:"当前楼层高于64",
      startCon:"开始连接",
      disCon:"连接断开",
      exceptionCon:"连接异常",
      startAu:"开始认证",
      norCon:"连接正常",

    },
    generateElevatorRecords: "生成电梯档案",
    seconds: " 秒",
    hide: "隐藏",
    moveUp: "上移",
    moveDown: "下移",
    minutes: "分钟",
    keywordSearch: "关键词检索",
    searchFilter: "关键字检索",
    watch: "观看",
    advanceSearch: "高级查询",
    pleasePartsSn: "必须34个字符",
    chooseAnArea: "未选择",
    select: "选择",
    detail: "详情",
    setting: "设置",
    setBlack: "确定设置选择的网关设备为黑名单吗?",
    cancelBlack: "确定选择的网关设备取消黑名单吗?",
    settingError: "设置失败",
    isAdd: "确定添加吗",
    alarm: "报警记录",
    beginTime: "开始时间",
    endTime: "结束时间",
    confirmTip: "确定删除此行吗",
    more: "展开",
    less: "收缩",
    set: "设置",
    isGenerate: "确定吗",
    efficient: "有效",
  },

  //统计报告
  statsReport: {
    report: "统计报告",
    positions: "当前位置 > 统计报告",
    cycle: "统计周期",
    pdf: "打印pdf",
    info: "电梯信息",
    name: "电梯名称",
    no: "电梯编号",
    type: "梯种",
    code: "设备代码",
    number: "出厂编号",
    unit: "使用单位",
    companyInfo: "企业信息",
    madeCompany: "制造企业",
    maintCompany: "维保企业",
    worker1Name: "维保工1姓名",
    worker1Phone: "维保工1电话",
    worker2Name: "维保工2姓名",
    worker2Phone: "维保工2电话",
    rate1: "故障停梯率：",
    rate2: "故障困人率：",
    time1: "维保停梯时间：",
    time2: "故障停梯时间：",
    time3: "救援响应时间：",
    time4: "救援时间：",
    percent: "占比",
    faultPercnet: "故障占比",
    month: "本月",
    sixMonth: "最近六个月",
    year: "今年至今",
  },
};
